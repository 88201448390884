jQuery(document).ready($ => {
  $('.ig-es-form-list-selection [name="lists[]"]').change(e => {
    let check = $(e.currentTarget);
    let label = check.parent('label');

    if(check.is(':checked')) {
        label.addClass('active');
    } else {
        label.removeClass('active');
    }
  })
});

jQuery(document).ready(function($) {

    // Seleciona Cartão de crédito caso altere para PF
    $('#billing_persontype').change(function(){
        if($('#payment_method_loja5_woo_novo_erede').length) {
            $('#payment_method_loja5_woo_novo_erede').click();
        }
    });

    $('.woocommerce-terms-and-conditions-wrapper .woocommerce-terms-and-conditions-link').on('click', function() {

        $('#termsConditionsModal').modal('show');

        this.preventDefault();
    });


    // update cart when change qty
    if($('.woocommerce .qty').length && $('[name=update_cart]').length) {
        jQuery( '.woocommerce' ).on( 'change', '.qty', function () {
            jQuery( "[name='update_cart']" ).trigger( "click" );
        } );
    }
});

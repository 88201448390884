/********************************************
/**
/* Modal para receber a programação do Curso 
/*
/********************************************/
jQuery(document).ready(($) => {

	var el = $('#single-product-form');
	var elSuccess = $('#receberProgramacaoCursoSuccess');

	// Quando clicar no botão download fecha modal
	el.find('.receberProgramacaoCursoForm').submit(function(e) {
		e.preventDefault();

		addLoader();

		modalAlertHide();

		var nome_completo = $(this).find('#nome_completo').val();
		var email = $(this).find('#email').val();
		var curso_id = $(this).find('#curso_id').val();
		var telefone = $(this).find('#telefone').val();
		var erros = 0;

		if(nome_completo.length == 0 || nome_completo == null){
			removeLoader();
			modalAlertShow("Preencha o nome completo.");
			erros++;
			return;
		}

		if(email.length == 0){
			removeLoader();
			modalAlertShow("Preencha o e-mail.");
			erros++;
			return;
		}
		if(!validateEmail(email)){
			removeLoader();
			modalAlertShow("Preencha um e-mail válido.");
			erros++;
			return;
		}

		if(telefone.length < 8){
			removeLoader();
			modalAlertShow("Preencha o Telefone.");
			erros++;
			return;
		}

		if(erros == 0)
			ajaxDownload(nome_completo, email, curso_id, telefone);
	});

	function addLoader() {
		$('.btn-quero-curso').attr('disabled', 'disabled').html('Aguarde, enviando...');
	}

	function removeLoader() {
		$('.btn-quero-curso').removeAttr('disabled').html('Eu quero');
	}

	function modalAlertShow(msg){
		el.find('.alert').removeClass('d-none');
		el.find('.alert').html(msg);
	}

	function modalAlertHide(){
		el.find('.alert').addClass('d-none');
	}

	function modalClearForm(){
		el.find('input[type="text"]').val('');
		el.find('input[type="email"]').val('');
	}

	function validateEmail(email){
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

	function ajaxDownload(nome_completo, email, curso_id, telefone){
		$.ajax({
			url: "/wp-admin/admin-ajax.php",
		    type: "post",
		    dataType: "json",
		    data: {
		        action: 'download_modal_ajax',
		        nome_completo: nome_completo,
		        email: email,
		        curso_id: curso_id,
				telefone: telefone,
		    }
	    }).done(function( data ){
	    	if(data.success == false){
				removeLoader();
				modalAlertShow(data.data);
	    	} else {
				modalAlertHide();
				modalClearForm();
				window.$('#receberProgramacaoCurso').modal('hide');
				elSuccess.find('.email_success').html(email);
				setTimeout(function(){
					window.$('#receberProgramacaoCursoSuccess').modal('show');
					removeLoader();
				}, 800);
	    	}
	    }).fail(function(jqXHR, textStatus, msg){
			removeLoader();
			modalAlertShow(msg);
		});
	}

});
/***************************************/
/***************************************/
/***************************************/
jQuery(document).ready(function($) {
	/**
	 * Run necessary function to init the selec fields.
	 */
	function init() {
    add_animation_to_product_card();

    // keep looking for new product-card
    add_animation_to_new_product_card();

    second_menu_active();

    infinite_category_menu_active();

    reorderProducts();

  }

    /**
     * Order the product-card-featured first, by popularity, then, all those small cards
     */

  function reorderProducts(){
	    let AllProducts = $('li.product-card');

        AllProducts.each(function(){
            fixProductPagination($(this));
            let isProductFeatured = $(this).hasClass('product-featured');
            if (!isProductFeatured) {
                $($(this).attr('visibility','hidden'))
                $('ul.products').append($(this));
            }
            else{
                $(this).show().attr('visibility','visible');
            }
        });
  }

  //@Todo debug and understand why some products are not getting the data-page attribute
  function fixProductPagination($producCard){
      if (($producCard).attr('data-page') == ''){
          $producCard.attr('data-page',2);
      }
  }

	/**
	 * Loop trough all date options and check if they're attached to a variation
	 * from variations array, defined by the selected place.
	 * @param  {array}  variations Product variations ID's.
	 */
	function add_animation_to_product_card() {
    // Loop trough all date options
    var $shop_grid = $('body').find('.shop-grid');
    var $products_card = $shop_grid.find('.products').find('.product-card');
    if($shop_grid.length > -1 && $products_card.length > -1) {
      $products_card.each( function( index ) {
        if(index > 4) {
          if(!$(this).attr('data-aos')) {
            $(this).attr('data-aos', 'fade-up');
          }
        }
      });
    }
  }
  
  function add_animation_to_new_product_card() {
    $('body').on('DOMNodeInserted', '.product-card', function () {
      add_animation_to_product_card();
    });
  }

  function second_menu_active() {
    if(jQuery('.current-item-menu .second-level .dropdown-item').length) {
      jQuery('.current-item-menu .second-level').hover(function () {
        jQuery('.current-item-menu .second-level .dropdown-item').removeClass('active');
      });
    }
  }
  
  function infinite_category_menu_active() {
    jQuery('.current-item-menu').on('hide.bs.dropdown', function () {
      if(jQuery('.third-level-container').length) {
        jQuery(this).find('.second-level:first .dropdown-item:first').addClass('active');
        jQuery(this).find('.third-level-container:first').addClass('show');
      }
    });
  }

	init();
});

export default {
  states: {

  },

  elements: {},

  initialize(id) {
    this.initializeElements(id);
    this.registerEvents();
  },

  initializeElements(id) {
    this.elements = {
      $modal: $(id),
    };
  },

  /**
   * Abre e fecha menu mobile e troca estado do hamburger no click. Não remove a
   * cor de fundo do header ao fechar se o menu estiver com a cor por meio do scroll.
   * @see this.mainMenuBackgroundScroll()
   */
  registerEvents() {
    if (! this.elements.$modal.length) {
      return;
    }

    this.elements.$modal.on('show.bs.modal', event => {
      let $modal = $(event.currentTarget);
      if($modal.find('iframe').length == 0) {
        let videoHTML = $modal.find('#videolazyLoad').html();
        $modal.find('.video-container').html(videoHTML);
      }

      let video = $modal.find('iframe');

      if(video.length > 0) {
        let videoSrc = video.attr('src');
        video.attr('src', videoSrc+'&autoplay=1&enablejsapi=1');
      }
    });

    this.elements.$modal.on('hide.bs.modal', event => {
      let $modal = $(event.currentTarget);
      let video = $modal.find('iframe');
      if(video.length > 0) {
        let videoSrc = video.attr('src').replace('&autoplay=1', '');
        video.attr('src', videoSrc);
      }
    });
  },
};

jQuery(document).ready(function($) {
    var previousScroll = 0;

	$(window).scroll(function() {
		var scroll = $(this).scrollTop();
		var filter = $('#shop-filters');

		if (scroll > 400) {
			filter.addClass('show');
		} else {
	      	filter.removeClass('show');
	    }

		previousScroll = scroll;

	});
});

jQuery(document).ready(function($) {
	// Solution Modals
	$('#games-modal').on('show.bs.modal', function() {
		$('.games-trigger-title').toggleClass('show-triggered');
	});

	$('#games-modal').on('hide.bs.modal', function() {
		$('.games-trigger-title').toggleClass('show-triggered');
	});

	$('#incompany-modal').on('show.bs.modal', function() {
		$('.incompany-trigger-title').toggleClass('show-triggered');
	});

	$('#incompany-modal').on('hide.bs.modal', function() {
		$('.incompany-trigger-title').toggleClass('show-triggered');
	});

	// A Integração Modals
	$('#views-modal').on('show.bs.modal', function() {
		$('.view-trigger-title').toggleClass('show-triggered');
	});

	$('#views-modal').on('hide.bs.modal', function() {
		$('.view-trigger-title').toggleClass('show-triggered');
	});

	$('#mission-modal').on('show.bs.modal', function() {
		$('.mission-trigger-title').toggleClass('show-triggered');
	});

	$('#mission-modal').on('hide.bs.modal', function() {
		$('.mission-trigger-title').toggleClass('show-triggered');
	});
});

import 'sticky-kit/dist/sticky-kit';

jQuery(document).ready(function($) {
	var scrollTop = $('.btn-scroll');

	scrollTop.stick_in_parent({
		bottoming: false,
	});
	
	if ( $('#footer-form') > 0 ) {

	  	$(window).scroll(function() {
	    	var topPos = $(this).scrollTop();

	    	// if user scrolls down - show scroll to top button
	    	if ( ( $('#footer').position().top + $('#footer-form').position().top ) > $(window).height() ) {
	      		$(scrollTop).css('opacity', '1');
			} else {
	      		$(scrollTop).css('opacity', '0');
	    	}
	  	});

		//Click event to scroll to top
		$(scrollTop).click(function() {
			$('html, body').animate({
			  scrollTop: 0
			}, 800);
			return false;
		});
	}
});

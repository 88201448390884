/**
 * Main
 *
 * You can write latest EcmaScript version in this file and it'll be
 * transpiled with Babel on npm run babel or npm run build:js.
 * More information in documentation: https://github.com/HasteDesign/Integracao/wiki
 */

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-mask-plugin/dist/jquery.mask.js';
import 'iframe-lightbox';
import 'sticky-kit/dist/sticky-kit';
import AOS from 'aos';

import 'bootstrap';
import './header.js';
import './modal.js';
import './dropdown.js';
import './sliders.js';
import './category.js';
import './checkout.js';
import './filters/filter-scroll.js';
import './forms/masks.js';
import './forms/newsletter.js';
import './scroll-top.js';
import modalVideo from './modalVideo.js';
import './download-modal.js';

jQuery(document).ready(function($) {
	AOS.init({
		once: true,
	});

	[].forEach.call(document.getElementsByClassName("iframe-lightbox-link"), function (el) {
		el.lightbox = new IframeLightbox(el);
	});


	modalVideo.initialize('[id^="modalVideo"]');

	// Contact
	$('#contact-btn').click( function() {
		$('#contact-btn .contact-open').toggle();
		$('#contact-btn .contact-close').toggle();
		$('#contact-btn').toggleClass('btn-active');
		$('.home-hub-grid').toggleClass('display-form');
		$('.sidebar-form').toggleClass('display-form');
	});

	// Add to Cart - Mobile
	$('.add-to-cart-toggle .btn-dark').click( function() {
		$('.product-add-to-cart').css('display', 'grid');
		// $('.product-add-to-cart.toggle').css('max-height', '500px');
	});

	$('a .close-add-cart').click( function() {
		$('.product-add-to-cart:not(.add-to-cart-toggle)').css('display', 'none');
	});

	$('.sidebar-chat-icons').hover(function(){
		$('.sidebar-chat-icons .btn-whatsapp').fadeToggle('fast').css("display","inline-block");
	});

	$('#jvlabelWrap').css('opacity', '0');
	$('.__jivoMobileButton').css('opacity', '0');

	$('.sidebar-chat-icons .btn-chat').on('click', function(){
		$('#jvlabelWrap').css('opacity', '1');
		$('#jvlabelWrap').css('margin-bottom', '0');
		if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			jivo_api.open();
		} else {
			if ($(this).hasClass('opened')){
				jivo_api.open();
			}
			$(this).toggleClass('opened');
		}
	});

	$('.sidebar-chat-icons-homehub').hover(function(){
		$('.sidebar-chat-icons-homehub .btn-whatsapp').fadeToggle('fast').css("display","inline-block");
	});

	$('.sidebar-chat-icons-homehub .btn-chat').on('click', function(){
		$('#jvlabelWrap').css('opacity', '1');
		$('#jvlabelWrap').css('margin-bottom', '0');
		if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			jivo_api.open();
		} else {
			if ($(this).hasClass('opened')){
				jivo_api.open();
			}
			$(this).toggleClass('opened');
		}
	});
	
//click em um span com href
	$('body').on('click', 'span', function(e) {
		var href = $(this).attr('href');
	    if (($(e.target).parents('a').length == 0) || $(e.target).length == 0) {
			if(typeof href !== 'undefined' && href !== false){
				window.open(href,"_self");
				return;
			}
		}
	});

    $('body').on('click', '.product-card .clickable', function (e) {
        e.preventDefault();
        window.location.href = $(this).attr('href');
        return false;
    });


	/**
	 * Disable WPCF7 button while it's submitting
	 * Stops duplicate enquiries coming through
	 */
	document.addEventListener( 'wpcf7submit', function( event ) {
		
		// find only disbaled submit buttons
		var button = $('.wpcf7-submit[disabled]');

		// grab the old value
		var old_value = button.attr('data-value');

		// enable the button
		button.prop('disabled', false);

		// put the old value back in
		button.val(old_value);

	}, false );

	$('form.wpcf7-form').on('submit',function() {

		var form = $(this);
		var button = form.find('input[type=submit]');
		var current_val = button.val();

		// store the current value so we can reset it later
		button.attr('data-value', current_val);

		// disable the button
		button.prop("disabled", true);

		// tell the user what's happening
		button.val("");

	});

	if($('body').hasClass('page-template-page-incompany')){
		$('#assunto-form-contato').hide();
		$('#assunto-form-contato select').val('Cursos In Company');
	}

	//CONTADOR BANNER
	let hour = $('#banner_hour_time').val();
	let minute = $('#banner_minute_time').val();
	let second = $('#banner_second_time').val();

	function start() {
		setInterval(() => { timer(); }, 1000);
	}

	function timer() {
		second -= 1;
		if (second < 0) {
			second = 59;
			minute--;
		}
		if (minute < 0) {
			minute = 59;
			hour--;
		}
		if (hour < 0) {
			hour = 0;
		}
		
		$('.banner_hour').html(returnData(hour));
		$('.banner_minute').html(returnData(minute));
		$('.banner_second').html(returnData(second));
	}

	function returnData(input) {
		return input >= 10 ? input : `0${input}`
	}
	start();
});

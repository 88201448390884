import Swiper from 'swiper';

jQuery(document).ready(function($) {
	// Photo gallery slider used in About, InCompany, Polo Itaim, etc.
	var about = new Swiper('.images-slider', {
		pagination: {
			el: '.swiper-pagination',
			type: 'fraction',
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	});
	
	var people_text = new Swiper('.people-text-slider', {
		loop: true,
		navigation: {
			nextEl: '.people-text-button-next',
			prevEl: '.people-text-button-prev',
		},
		breakpoints: {
			// when window width is >= 768px
			768: {
				loop: false,
			},
		},
	});

	// Leadership
	var people = new Swiper('.people-image-slider', {
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		loopFillGroupWithBlank: false,
		navigation: {
			nextEl: '.people-button-next',
			prevEl: '.people-button-prev',
		},
		pagination: {
			el: '.people-pagination',
			type: 'fraction',
		},
		on: {
		    slideChange: function () {
		      people_text.slideTo( this.realIndex )
		    },
		},
		breakpoints: {
			// when window width is >= 640px
			640: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			// when window width is >= 1280px
			992: {
				slidesPerView: 2,
				spaceBetween: 0
			}
		},
	});

	// Banner
	var banner = new Swiper ('.banner-slider', {
		autoplay: {
			delay: 4000,
		},
		pagination: {
			el: '.swiper-pagination',
		}
	});

	// Cards
	var cards = new Swiper('.card-slider', {
		slidesPerView: 2,
      	spaceBetween: 0,
		// freeMode: true,
		loop: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			320: {
			  slidesPerView: 1,
			  spaceBetween: 0
			},
			// when window width is >= 480px
			480: {
			  slidesPerView: 1,
			  spaceBetween: 0
			},
			// when window width is >= 640px
			640: {
			  slidesPerView: 1,
			  spaceBetween: 0
		  	},
			// when window width is >= 1280px
		  	1280: {
			  slidesPerView: 2,
  			  spaceBetween: 0
			}
	  	},
		navigation: {
			nextEl: '.swiper-button-next',
		}
	});

	// Multi-step Form
	var form = new Swiper('.slider-multistep-form', {
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
    		type: 'bullets',
		}
	});

	// Cashback Page Testimonials
	var cashbackTestimonial = new Swiper('.cashback-testimonial-slider', {
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			300: {
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: true,
			},
			// when window width is >= 768px
			768: {
				slidesPerView: 1,
				spaceBetween: 60,
				centeredSlides: true
			},
			992: {
				slidesPerView: 2,
				spaceBetween: 60
			}
		},
		loop: false,
	});

	// Conquistar Games Carousel
	var conquistarIntro = new Swiper('.conquistar-games', {
		// Responsive breakpoints
		breakpoints: {
			300: {
				slidesPerView: 1,
				centeredSlides: true,
			},
			// when window width is >= 768px
			768: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 2,
			}
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		loop: false,
	});

	// Conquistar depoimentos
	var conquistarDepoimentos = new Swiper('.conquistar-depoimentos', {
		// Responsive breakpoints
		breakpoints: {
			300: {
				slidesPerView: 1,
				centeredSlides: true,
			},
			// when window width is >= 768px
			768: {
				slidesPerView: 2,
				spaceBetween: 50,
			},
			992: {
				slidesPerView: 4,
				spaceBetween: 50,
			}
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		loop: false,
	});

	// Testimonials
	var testimonial = new Swiper('.testimonial-slider', {
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			300: {
			  slidesPerView: 1,
			  spaceBetween: 0
			},
			// when window width is >= 768px
			768: {
			  slidesPerView: 2,
  			  spaceBetween: 60
			},
			992: {
			  slidesPerView: 3,
  			  spaceBetween: 60
			}
		},
		navigation: {
			nextEl: '.testimonial-video-button-next',
			prevEl: '.testimonial-video-button-prev',
		},
		pagination: {
			el: ".testimonial-pagination",
		},
		loop: true,
		lazy: true,
	});

	// Coracoes
	var hearth = new Swiper('.testimonial-hearth-slider', {
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			300: {
			  slidesPerView: 1,
			  spaceBetween: 0
			},
			// when window width is >= 768px
			768: {
			  slidesPerView: 2,
  			  spaceBetween: 60
			},
			992: {
			  slidesPerView: 3,
  			  spaceBetween: 60
			}
		},
		navigation: {
			nextEl: '.hearth-video-button-next',
			prevEl: '.hearth-video-button-prev',
		},
		loop: true,
		lazy: false,
	});

	// Testimonials
	var incompany_testimonial = new Swiper('.incompany-testimonial-slider', {
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			300: {
			  slidesPerView: 1,
			  spaceBetween: 0
			},
			// when window width is >= 768px
			992: {
			  slidesPerView: 2,
  			  spaceBetween: 20
			}
		},
		loop: false,
		lazy: true,
		navigation: {
			nextEl: '.testimonial-video-button-next',
			prevEl: '.testimonial-video-button-prev',
		}
	});

	// In Company Toolkit
	var toolkits = new Swiper('.toolkit-slider .swiper-container', {
		slidesPerView: 1,
      	spaceBetween: 0,
		loop: true,
		navigation: {
			nextEl: '.banner-video-button-next',
			prevEl: '.banner-video-button-prev',
		},
		lazy: true,
	});

	// I+5
	var i5_slider = new Swiper('.i5_slider .swiper-container', {
		loop: true,
		// Responsive breakpoints
		breakpoints: {
			// when window width is >= 320px
			300: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			// when window width is >= 768px
			992: {
				slidesPerView: "auto",
				spaceBetween: 20,
			}
		},
		navigation: {
			nextEl: '.i5-video-button-next',
			prevEl: '.i5-video-button-prev',
		},
	});

	window.addEventListener('load', () => {
		// Resize refreshes sliders
		window.dispatchEvent(new Event('resize'));

	}, false);
	
});
jQuery(document).ready(($) => {

    let alterClass = () => {
        let ww = document.body.clientWidth;
        if (ww < 1200) {
            $('#schools-menu').removeClass('navbar-nav');
            $('#schools-menu').addClass('dropdown-menu');
            $('.schools-nav').addClass('btn-group');
        } else if (ww >= 1201) {
            $('#schools-menu').removeClass('dropdown-menu');
            $('#schools-menu').addClass('navbar-nav');
            $('.schools-nav').removeClass('btn-group');
        };
	};
	
	let fixHeader = () => {
        let header = $('#header.schools-header');
        let menuMain = $('.schools-menu.dropdown-menu');
        let menuTop = $('#navigation-top .navbar-toggler');
        
		if(header.length) {
            header.stick_in_parent({
                parent: $('body'),
            });

            let lastScrollPosition = parseInt($(window).scrollTop());
            $(window).scroll(() => {
                let scrollPosition = parseInt($(window).scrollTop());
                if(menuMain.length > 0 && menuMain.is('.show')) {
                    return;
                }

                if(menuTop.length > 0 && !menuTop.is('.collapsed') && menuTop.is(':visible')) {
                    return;
                }

                if(lastScrollPosition > scrollPosition) {
                    header.removeClass('stuck');
                } else {
                    if(scrollPosition > 100) {
                        header.addClass('stuck');
                    }
                }

                lastScrollPosition = scrollPosition;
            });
		}
	};
    
    $(window).resize(function(){
        alterClass();
    });
    //Fire it when the page first loads:
	alterClass();
    fixHeader();
    
    if($('body').hasClass('single-post') && $('#menu-menu-superior').length) {
        let $menuBlog = $('#menu-menu-superior').find('a[title="Blog"]');
        if($menuBlog.length && !$menuBlog.hasClass('active')) {
            $menuBlog.addClass('active');
        }
    }
});

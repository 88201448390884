jQuery(document).ready(function($) {
	// Search
	$( '#search-toggle' ).click(function () {
		setTimeout(function() {
			$('#s').focus();
		}, 500);
	});

	// Menu dropwdown
	$( '.dropright' ).hover(
	    function () {
			$( this ).children('.dropdown-menu').addClass( 'show' );
		},
		function () {
			$( this ).children('.dropdown-menu').removeClass( 'show' );
		}
	);

	$( '.current-school-nav .second-level a' ).click( function() {
		location.href = this.href;
	});

	$( '.current-school-nav .third-level a' ).click( function() {
		location.href = this.href;
	});
});

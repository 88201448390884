jQuery(document).ready(function($) {
	if ( jQuery().mask() ) {
		jQuery( '.wpcf7-text[name="cnpj"]' ).mask( '00.000.000/0000-00' );
		jQuery( '.wpcf7-text[name="cep"]' ).mask( '00000-000' );

		var behavior = function (val) {
				return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
			},
			options = {
				onKeyPress: function (val, e, field, options) {
					field.mask(behavior.apply({}, arguments), options);
				}
			};

		jQuery('.wpcf7-text[name="telefone"], .phone').mask(behavior, options);
	}
});
